var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable */
import Button from '@fp-suite/core-ui/src/components/Button';
import Input from '@fp-suite/core-ui/src/components/Input';
import Table from '@fp-suite/core-ui/src/components/Table';
import { Form } from 'antd';
import React, { useEffect } from 'react';
var PackShipmentItems = function (_a) {
    var allShipmentPackageContentList = _a.allShipmentPackageContentList, allShipmentPackageContentSerialNumberList = _a.allShipmentPackageContentSerialNumberList, packageSeqId = _a.packageSeqId, onPackageItemQtyUpdate = _a.onPackageItemQtyUpdate, shipmentPackage = _a.shipmentPackage, statusId = _a.statusId;
    var form = Form.useForm()[0];
    var weight = shipmentPackage[packageSeqId].some(function (item) { return item === null || item === void 0 ? void 0 : item.weight; });
    useEffect(function () {
        var _a, _b;
        if (((_a = allShipmentPackageContentList[packageSeqId]) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var newFieldValues_1 = {};
            (_b = allShipmentPackageContentList[packageSeqId]) === null || _b === void 0 ? void 0 : _b.forEach(function (item) {
                newFieldValues_1["Qty_" + (item === null || item === void 0 ? void 0 : item.productId)] = item === null || item === void 0 ? void 0 : item.quantity;
            });
            form.setFieldsValue(newFieldValues_1);
        }
    }, [allShipmentPackageContentList[packageSeqId]]);
    var packageSummaryItemsColumns = [
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5 ' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Product" }), void 0) }), void 0)),
            render: function (productSku, data) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: "" + (productSku || (data === null || data === void 0 ? void 0 : data.productUpc)) + ': ' + data.productName }), void 0)); },
            dataIndex: 'productSku'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5 ' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Serial No." }), void 0) }), void 0)),
            render: function (productId, data) {
                var _a, _b;
                return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: ((_a = allShipmentPackageContentSerialNumberList[packageSeqId]) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                        ((_b = allShipmentPackageContentSerialNumberList[packageSeqId]) === null || _b === void 0 ? void 0 : _b.map(function (item, index) {
                            if (item.productId === productId &&
                                item.shipmentPackageSeqId === data.shipmentPackageSeqId) {
                                return (_jsxs(React.Fragment, { children: [item.serialNumber, _jsx("br", {}, void 0)] }, index));
                            }
                            return null;
                        })) }), void 0));
            },
            dataIndex: 'productId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Image" }), void 0) }), void 0)),
            render: function (image) { return _jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: image }), void 0); },
            width: 250,
            dataIndex: 'image'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Qty" }), void 0) }), void 0)),
            render: function (quantity, data) { return (_jsx(_Fragment, { children: _jsxs(Form, __assign({ className: 'flex ', form: form }, { children: [_jsx(Form.Item, __assign({ name: "Qty_" + (data === null || data === void 0 ? void 0 : data.productId) }, { children: _jsx(Input, { placeholder: '', title: '', autoFocus: true }, void 0) }), void 0), _jsx(Form.Item, __assign({ className: 'mx-10' }, { children: _jsx(Button, { isDisabled: statusId === 'ShipPacked' ? true : false, buttonText: 'Update', paddingX: 'px-3', defaultColor: 'bg-blue-600 m-2', onClick: function () {
                                    var qty = form.getFieldValue("Qty_" + (data === null || data === void 0 ? void 0 : data.productId));
                                    onPackageItemQtyUpdate(data === null || data === void 0 ? void 0 : data.productId, qty, packageSeqId);
                                }, type: 'button' }, void 0) }), void 0)] }), void 0) }, void 0)); },
            dataIndex: 'quantity'
        }
    ];
    return (_jsx(Table, { columns: packageSummaryItemsColumns, dataSource: allShipmentPackageContentList[packageSeqId], isPaginated: false }, 'pickRunId'));
};
export default PackShipmentItems;
